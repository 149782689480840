import React from 'react';
import { Provider as RollbarProvider } from '@rollbar/react';
import Rollbar from 'rollbar';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';

import './index.css';
import IndexPage from './pages';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
  auth: process.env.REACT_APP_GTM_AUTH || '',
  preview: process.env.REACT_APP_GTM_ENV_ID || '',
};
TagManager.initialize(tagManagerArgs);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);
const env = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'unknown';
const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || '',
  enabled: env !== 'ci' && env !== 'development',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: env,
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
};

// Prevent PWA install prompt
window.addEventListener('beforeinstallprompt', (e) => {
  console.log('beforeinstallprompt fired - preventing default');
  e.preventDefault();
});

const rollbar = new Rollbar(rollbarConfig);

// Store global Rollbar instance
declare global {
  interface Window {
    rollbar: Rollbar;
  }
}
if (typeof window !== 'undefined') window.rollbar = rollbar;

// Example of using Rollbar as a hook:
// import { useRollbar } from '@rollbar/react';
// ...
// const rollbar = useRollbar();
// rollbar.info('message text');

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RollbarProvider instance={rollbar}>
        <Provider store={store}>
          <IndexPage />
        </Provider>
      </RollbarProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
