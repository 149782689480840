import React, { useEffect, useRef, useState } from 'react';

import {
  Grid,
  GridItem,
  Button,
  Typography,
  Box,
  Icon,
  TextField,
} from '@worthy-npm/worthy-common-ui-components';
import { StepGridContainer, Step, StepContainer, NextStepButton } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectBrand,
  selectItemType,
  updateBrand,
  updateCustomBrand,
  submitItem,
  selectSubmitAPI,
} from '../../../slices/submitSlice';
import { FormContent, FormWrap, Img, Notice, StepProps } from '../common';
import { parametrize } from '../../../lib/commonUtils';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';
import { isItemRejected } from '../../../validation/validations';

function StepSubmit({ exit, idx, stepName, title, stepCaption, active, props }: StepProps) {
  const dispatch = useAppDispatch();
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (active) GA.stepView(stepName);
  }, [active]);

  const hasRun = useRef(false);

  useEffect(() => {
    const submitData = async () => {
      if (hasRun.current) {
        return;
      }
      hasRun.current = true;
      const status = await dispatch(submitItem());
      const { rejected, rejectReason } = isItemRejected(status.payload.item);
      if (exit) {
        if (rejected) {
          exit('reject');
        } else {
          exit('next');
        }
      } else {
        throw new Error('no exit');
      }
    };
    submitData();
  }, [active]);

  return (
    <StepContainer data-automation="submit-step">
      <Step>
        <StepTitle
          idx={idx}
          prev={() => exit && exit('prev')}
          stepTitle={title || ''}
          stepName={stepName}
          stepCaption={stepCaption}
          stepNotice=""
          optionalProps={props || {}}
        />
      </Step>
    </StepContainer>
  );
}

export default StepSubmit;
