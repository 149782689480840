import _ from 'lodash';
import { useEffect, useState } from 'react';
import { CookieStore } from '../services/cookieStore';

export const getExperiment = (experimentName: string): string => {
  const experimentsCookie: string = CookieStore.getExperiments();
  const experimentObject = _.reduce(
    experimentsCookie.split('§'),
    (obj: Record<string, string>, exp) => {
      const [expName, expVariant] = exp.split('±');
      if (expName) {
        _.set(obj, expName, expVariant);
      }
      return obj;
    },
    {},
  );
  return experimentObject[experimentName] || '';
};

function getExperimentCookieValue(experimentName: string) {
  // Cookie format: _wor_opt_exp_=experiment1±variant1§experiment2±variant2§experiment3±variant3
  const experimentsCookie: string = CookieStore.getExperiments();
  const experimentStrings = experimentsCookie.split('§');
  const experiments = experimentStrings.map((experimentString: string) =>
    experimentString.split('±'),
  );
  const exp = experiments.find((experiment) => experiment[0] === experimentName);
  return exp ? exp[1] : undefined;
}

export function useExperiment(
  experimentName: string,
  defaultValue: string | undefined = undefined,
) {
  console.debug(`experiment ${experimentName} defaultValue: `, defaultValue);
  const experimentInitialValue = getExperimentCookieValue(experimentName) || defaultValue;
  const [experiment, setExperiment] = useState(experimentInitialValue);
  console.debug(`experiment ${experimentName} initialValue: `, experimentInitialValue);
  useEffect(() => {
    const updateExperiment = (name: string, value: string) => {
      console.debug(`experiment ${experimentName} updateExperiment: `, name, value);
      setExperiment(value);
    };
    const eventCallback = (event: Event) => {
      const detail = (event as CustomEvent).detail;
      console.debug(`experiment ${experimentName} callback: `, detail);
      if (detail.experimentSystemName === experimentName) {
        updateExperiment(detail.experimentSystemName, detail.variantId);
      }
    };
    window.addEventListener('experimentImpression', eventCallback);

    return () => {
      // cleanup
      window.removeEventListener('experimentImpression', eventCallback);
    };
  }, []);

  console.debug(`experiment ${experimentName} returning: `, experiment);
  return experiment;
}

export function useBooleanExperiment(
  experimentName: string,
  defaultValue: string | undefined = undefined,
) {
  const experiment = useExperiment(experimentName, defaultValue);
  return !!(experiment && experiment !== '0');
}

export function useNumericExperiment(
  experimentName: string,
  defaultValue: number | undefined = undefined,
) {
  const experiment = useExperiment(experimentName, (defaultValue || 0).toString());
  return Number(experiment) || 0;
}

export default {
  useExperiment,
};
